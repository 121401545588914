import React, { useState, useEffect } from "react";
import { Container, Box, TextField, Typography, FormHelperText, Autocomplete } from "@mui/material";
import MKButton from "muikit-components/MKButton";
import { Alert } from "@mui/material";

const teachers = ["Яна", "Ірина", "Марина", "Софія", "Анна"];

const lessonDurations = ["1 година", "1.5 години"];

const lessonsPerWeekOptions = [1, 2, 3, 4];

const ContactForm = ({ setSelectedTeacher, selectedTeacher }) => {
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    teacher: "",
    lessonsPerWeek: "",
    lessonDuration: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    phone: false,
    teacher: false,
    lessonsPerWeek: false,
    lessonDuration: false,
    message: false,
  });

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setFormErrors({
      ...formErrors,
      [name]: value === "" || value == null || (typeof value === "string" && value.trim() === ""),
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value !== "" && (typeof value !== "string" || value.trim() !== "")) {
      setFormErrors({
        ...formErrors,
        [name]: false,
      });
    }
  };

  const handleAutocompleteChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (name === "teacher") {
      setSelectedTeacher(value);
    }
    if (value !== null && value !== "") {
      setFormErrors({
        ...formErrors,
        [name]: false,
      });
    } else {
      setFormErrors({
        ...formErrors,
        [name]: true,
      });
    }
  };

  useEffect(() => {
    if (selectedTeacher !== "") {
      handleAutocompleteChange("teacher", selectedTeacher);
    }
  }, [selectedTeacher]);

  const isFormValid = () => {
    return Object.entries(formValues).every(([name, field]) => {
      if (name === "message") return true;
      return field !== null && (typeof field !== "string" || field.trim() !== "");
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://worker.scienceschool.online", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setMessage("Ваша заявка була успішно відправлена!");
      setSeverity("success");
      setShowAlert(true);
    } catch (error) {
      console.error("error", error);
      setMessage("Упс! Сталась якась помилка...");
      setSeverity("error");
      setShowAlert(true);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 1,
          p: 3,
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: "background.paper",
        }}
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Запис на заняття
        </Typography>
        <TextField
          label="Ім'я"
          variant="outlined"
          required
          fullWidth
          name="name"
          value={formValues.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={formErrors.name}
          helperText={formErrors.name ? "Ім'я є обов'язковим" : ""}
        />
        <TextField
          label="Номер телефона"
          type="tel"
          variant="outlined"
          required
          fullWidth
          name="phone"
          value={formValues.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          error={formErrors.phone}
          helperText={formErrors.phone ? "Номер телефона є обов'язковим" : ""}
        />
        <Autocomplete
          options={teachers}
          getOptionLabel={(option) => option}
          value={selectedTeacher}
          onChange={(e, newValue) => handleAutocompleteChange("teacher", newValue)}
          onBlur={() => handleBlur({ target: { name: "teacher", value: formValues.teacher } })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Оберіть вчителя"
              variant="outlined"
              required
              error={formErrors.teacher}
              helperText={formErrors.teacher ? "Будь-ласка оберіть вчителя" : ""}
            />
          )}
        />
        <Autocomplete
          options={lessonsPerWeekOptions}
          getOptionLabel={(option) => option.toString()}
          value={formValues.lessonsPerWeek}
          onChange={(e, newValue) => handleAutocompleteChange("lessonsPerWeek", newValue)}
          onBlur={() =>
            handleBlur({ target: { name: "lessonsPerWeek", value: formValues.lessonsPerWeek } })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Кількість уроків на тиждень"
              variant="outlined"
              required
              error={formErrors.lessonsPerWeek}
              helperText={formErrors.lessonsPerWeek ? "Будь-ласка оберіть кількість уроків" : ""}
            />
          )}
        />
        <Autocomplete
          options={lessonDurations}
          getOptionLabel={(option) => option}
          value={formValues.lessonDuration}
          onChange={(e, newValue) => handleAutocompleteChange("lessonDuration", newValue)}
          onBlur={() =>
            handleBlur({ target: { name: "lessonDuration", value: formValues.lessonDuration } })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Тривалість уроку"
              variant="outlined"
              required
              error={formErrors.lessonDuration}
              helperText={formErrors.lessonDuration ? "Будь-ласка оберіть тривалість уроку" : ""}
            />
          )}
        />
        <TextField
          label="Повідомлення"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          name="message"
          value={formValues.message}
          onChange={handleChange}
          error={formErrors.message}
        />
        <FormHelperText error={!isFormValid()}>
          {!isFormValid() && "Будь-ласка заповніть всі обов'язкові поля форми"}
        </FormHelperText>
        <MKButton
          type="submit"
          variant="contained"
          color="info"
          fullWidth
          disabled={!isFormValid()}
        >
          Записатися
        </MKButton>
        {showAlert && (
          <Alert severity={severity} onClose={() => setShowAlert(false)} sx={{ mt: 2 }}>
            {message}
          </Alert>
        )}
      </Box>
    </Container>
  );
};

export default ContactForm;
